import { useState } from "react"

export const useAuthNew = () => {
  const [user, setUser] = useState(null)

  const isTokenExpired = (token) => {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    return Math.floor(new Date().getTime() / 1000) + 10 >= expiry
  }

  const updateAccessToken = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "auth/token/refresh/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      )
      if (res.ok) {
        const resData = await res.json()
        setUser({ ...user, access: resData.access })
        return resData.access
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const getAuthHeader = async (headers = {}) => {
    if (!user) return false
    let accessToken = user.access
    if (!accessToken || isTokenExpired(accessToken)) {
      accessToken = await updateAccessToken()
      if (!accessToken) {
        signOut()
        return false
      }
    }
    Object.assign(headers, { Authorization: `Bearer ${accessToken}` })
    return headers
  }

  const getProfile = async (token) => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "auth/profile/", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.ok) return await res.json()
    } catch {
      return false
    }
    return false
  }

  const signIn = async (username, password) => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "auth/token/", {
        method: "POST",
        body: JSON.stringify({ username: username, password: password }),
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json;charset=utf-8",
        },
      })

      if (!res.ok) {
        signOut()
        return false
      }

      const token = await res.json()
      const profile = await getProfile(token.access)

      if (profile) {
        const userObj = {
          id: profile?.id ? profile.id : undefined,
          username: profile.user.username,
          email: profile.user.email,
          access: token.access,
          type: profile.user?.type ? profile.user.type : "OWNER",
          // type: "OPERATOR",
        }
        setUser(userObj)
        return userObj
      }
    } catch {
      return false
    }

    return false
  }

  const restoreSignIn = async () => {
    // Пытаемся зайти в пользователя, если в cookies есть refresh token
    const token = await updateAccessToken()
    if (token) {
      const profile = await getProfile(token)
      const userObj = {
        id: profile?.id ? profile.id : undefined,
        username: profile.user.username,
        email: profile.user.email,
        access: token.access,
        type: profile.user?.type ? profile.user.type : "OWNER",
        // type: "OPERATOR",
      }
      setUser(userObj)
      return userObj
    } else {
      return false
    }
  }

  const signUp = async (d) => {
    const data = {
      username: d.login,
      email: d.email,
      password: d.password,
    }
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "auth/profile/", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json;charset=utf-8",
        },
      })
      const resData = await res.json()
      // if (res.ok && resData?.user){}
      return resData
    } catch {
      return false
    }
  }

  const signOut = () => {
    fetch(process.env.REACT_APP_API_URL + "auth/token/blacklist/", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((r) => {
        setUser("logout")
        return r.json()
      })
      .then((res) => setUser("logout"))
      .catch((e) => {
        console.error(e)
        setUser("logout")
      })
  }

  // const getAuthHeaderNew = () => {
  //   // Проверяем Access Token
  //   isTokenExpired()
  //   // Обновляем Access Token
  //   // Refresh token expired
  //   return false
  // }

  return { user, getAuthHeader, signIn, signUp, signOut, restoreSignIn }
}
