import React from "react"
import { Box, LinearProgress, Typography } from "@mui/material"

const LinearProgressWithLabel = ({ value, color }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            width: "100%",
            border: "none",
            background: "#EAF2FF",
            borderRadius: "10px",
            height: "15px",
          }}
          color={color}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function StatsProgress({ value, valuePer, title, color }) {
  return (
    <Box
      p={1}
      sx={{
        width: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Typography variant={"body2"} sx={{ width: "100%" }}>
        {`${title}: `}
        <Typography display={"inline"} variant="span" sx={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </Typography>
      <LinearProgressWithLabel value={valuePer} color={color} />
    </Box>
  )
}
