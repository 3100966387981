import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import {
  Box,
  TextField,
  InputLabel,
  Button,
  ButtonGroup,
  Typography,
  List,
  ListItem,
  Select,
  Input,
} from "@mui/material"
import { useOutletContext } from "react-router-dom"
import OperatorList from "./OperatorList"
import DepartmentList from "./DepartmentList"
import { useForm } from "react-hook-form"
import useConfig from "../../hooks/useConfig"

function Option({ header, children }) {
  return (
    <ListItem sx={{}}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography variant="span" mb={1}>
          {header}
        </Typography>
        {children}
      </Box>
    </ListItem>
  )
}

export default function Company() {
  const pagePadding = useOutletContext()
  const { config, saveConfig } = useConfig()
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" })

  const save = (d) => {
    console.log(d)
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        height: "100%",
        width: "100%",
        gap: pagePadding,
        boxSizing: "border-box",
      }}
    >
      <Header header={"Компания"} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          height: "100%",
          width: "100%",
          overflow: "auto",
          gap: pagePadding,
          padding: pagePadding,
          boxSizing: "border-box",
          backgroundColor: "#fff",
          boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
          borderRadius: "15px",
        }}
      >
        <Typography variant="h6">Настройки</Typography>
        <form
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            // height: "100%",
            width: "100%",
          }}
          onSubmit={handleSubmit(save)}
        >
          <List>
            <Option header={"Компания"}>
              <TextField
                variant="outlined"
                label={"Название компании"}
                {...register("name", {
                  maxLength: {
                    value: 150,
                    message: "Максимум 150 символов",
                  },
                })}
                error={errors?.name}
                helperText={errors?.name?.message}
              />
            </Option>
            <Option header={"Телефония"}>
              <Box>
                <Select label={"Телефония"}></Select>
                <TextField
                  {...register("call_token", {
                    maxLength: {
                      value: 150,
                      message: "Максимум 150 символов",
                    },
                  })}
                  variant="outlined"
                  label={"Токен"}
                  error={errors?.call_token}
                  helperText={errors?.call_token?.message}
                />
              </Box>
            </Option>
            <Option header={"Департаменты"}>
              <DepartmentList />
            </Option>
            {/* <Option header={"Операторы"}>
            <OperatorList />
          </Option> */}
          </List>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <ButtonGroup
              variant="contained"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "min-content",
                // boxShadow: "none",
              }}
            >
              <Button color={"secondary"}>Отмена</Button>
              <Button type="submit" color={"primary"}>
                Сохранить
              </Button>
            </ButtonGroup>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
