import React from "react"
import { Box, Typography, Grid } from "@mui/material"
import StatsProgress from "../../../components/StatsProgress"

const MasterFeedback = ({ master }) => {
  let data = []
  for (const [key, value] of Object.entries(master.feedback_analytics)) {
    if (value.title) {
      if (value.color) {
        if (value.percentage) {
          data.push({
            title: value.title,
            color: value.color.toLowerCase(),
            percentage: value.percentage,
          })
        }
      }
    }
  }
  return (
    <Box
      sx={{
        width: "50%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minWidth: "300px",
      }}
    >
      <Typography
        variant="body2"
        mt={2}
        sx={{ width: "100%", fontWeight: 600 }}
      >
        {`${master.name}`}
      </Typography>
      <Typography variant="body2">
        {`Всего реализаций: ${master.feedback_analytics.total}`}
      </Typography>
      {data.map((d) => (
        <StatsProgress
          key={d}
          title={d.title}
          value={d.count}
          valuePer={d.percentage ? d.percentage : 0}
          color={d.color}
        />
      ))}
    </Box>
  )
}

const Master = ({ master }) => {
  let data = []
  for (const [key, value] of Object.entries(master.calls_analytics)) {
    if (value.title) {
      if (value.color) {
        if (value.percentage) {
          data.push({
            title: value.title,
            color: value.color.toLowerCase(),
            percentage: value.percentage,
          })
        }
      }
    }
  }

  return (
    <Box
      sx={{
        width: "50%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minWidth: "300px",
      }}
    >
      <Typography
        variant="body2"
        mt={2}
        sx={{ width: "100%", fontWeight: 600 }}
      >
        {`${master.name}`}
      </Typography>
      <Typography variant="body2">
        Всего звонков:{` `}
        {master.calls_analytics?.total || "0"}
      </Typography>
      <Typography variant="body2">
        Обработано:{` `}
        {master.calls_analytics?.processed?.count || "0"}
      </Typography>
      {data.map((d) => (
        <StatsProgress
          key={d}
          title={d.title}
          value={d.count}
          valuePer={d.percentage || "0"}
          color={d.color}
        />
      ))}
    </Box>
  )
}

export default function Masters({ data }) {
  if (!data) return <></>

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#fff",
        boxSizing: "border-box",
        borderRadius: "15px",
        padding: "15px",
        overflowY: "auto",
      }}
    >
      <Typography variant="h6">Звонки</Typography>
      <Grid container>
        {data.map((m) =>
          m?.name ? (
            <Grid item>
              <Master master={m} key={m} />
            </Grid>
          ) : (
            <></>
          )
        )}
      </Grid>
      <Typography variant="h6" mt={2}>
        Обратная связь
      </Typography>
      <Grid container>
        {data.map((m) =>
          m?.name ? (
            <Grid item>
              <MasterFeedback master={m} key={m} />
            </Grid>
          ) : (
            <></>
          )
        )}
      </Grid>
    </Box>
  )
}
