import React from "react"
import { Box, List, ListItem, Typography } from "@mui/material"

const OperatorItem = ({ op: { name, quantity, duration } }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography>{name}</Typography>
      <Typography>{`${(duration / (1000 * 60)).toFixed(2)} мин`}</Typography>
      <Typography>{`${quantity} звонков`}</Typography>
    </Box>
  )
}

export default function Operator({ width, data }) {
  if (!data) return <></>

  const per = (partialValue, totalValue) =>
    ((100 * partialValue) / totalValue).toFixed(2)

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <List sx={{ overflow: "auto" }}>
        {data.map((op) => {
          if (op.name) {
            return <OperatorItem op={op} />
          }
        })}
      </List>
    </Box>
  )
}
