import React from "react"
import { Skeleton } from "@mui/material"
import Card from "./Card"
import useCalls from "../hooks/useCalls"
import { Cell, Legend, Pie, PieChart } from "recharts"

const COLORS = [
  "green",
  "red",
  "turquoise",
  "purple",
  "pink",
  "gray",
  "blue",
  "brown",
  "orange",
]

export default function General({ startDate, endDate, department }) {
  const { calls } = useCalls(startDate, endDate, department)
  let data = []
  if (calls?.participation) {
    data = calls.participation.map((c, index) => {
      return {
        name: c.name,
        value: c.participation,
        fill: COLORS[index],
      }
    })
  }

  return (
    <Card
      title={`Всего успешных звонков: ${calls?.succeed_calls?.count || "-"}, ${
        calls?.succeed_calls?.rate || "-"
      }%`}
    >
      {calls ? (
        <>
          <PieChart width={300} height={180}>
            <Legend layout="vertical" verticalAlign={"middle"} align="right" />
            <Pie
              data={data}
              label={false}
              labelLine={false}
              // innerRadius={width * 0.2 - 50}
              outerRadius={300 * 0.25}
              fill="#8884d8"
              // paddingAngle={5}
              dataKey="value"
              // radius={innerRadius + (outerRadius - innerRadius) * 0.5}
            ></Pie>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </PieChart>
        </>
      ) : (
        <Skeleton variant={"circular"} height={150} width={150} />
      )}
    </Card>
  )
}
