import React from "react"
import { Skeleton } from "@mui/material"
import Card from "./Card"
import useCalls from "../hooks/useCalls"
import StatsProgress from "../../../components/StatsProgress"

export default function Calls({ startDate, endDate, department }) {
  const { calls } = useCalls(startDate, endDate, department)
  return (
    <Card title={"Звонки"}>
      {calls ? (
        <>
          <StatsProgress
            color={"purple"}
            title={"Всего звонков"}
            value={calls?.calls || "-"}
            valuePer={100}
          ></StatsProgress>
          <StatsProgress
            color={"purple"}
            title={"Всего обработано"}
            value={calls?.processed_calls.count || "-"}
            valuePer={calls?.processed_calls.rate || 0}
          />
          <StatsProgress
            color={"purple"}
            title={"Всего успешно"}
            value={calls?.succeed_calls.count || "-"}
            valuePer={calls?.succeed_calls.rate || 0}
          />
        </>
      ) : (
        <>
          <Skeleton sx={{ width: "100%" }} height={50} />
          <Skeleton sx={{ width: "100%" }} height={50} />
          <Skeleton sx={{ width: "100%" }} height={50} />
        </>
      )}
    </Card>
  )
}
