import React, { useEffect, useState } from "react"
import Modal from "../../../components/Modal"
import {
  Typography,
  IconButton,
  Button,
  Box,
  List,
  ListItem,
} from "@mui/material"
import closeIcon from "../../../assets/images/close.png"
import { useAuthContext } from "../../../hocs/AuthProvider"
import { useSnackbarContext } from "../../../hocs/SnackbarProvider"
import axiosInstance from "axios"

const ModalHeader = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{`Подтвердите рассылку`}</Typography>
      <IconButton
        sx={{
          width: "40px",
          padding: "7px",
          marginRight: "-7px",
          height: "40px",
        }}
        onClick={onClose}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={closeIcon}
          alt="close"
        />
      </IconButton>
    </Box>
  )
}

const DataShowerItem = ({ title, value }) => (
  <ListItem alignItems="flex-start">
    <Typography variant="span" sx={{ fontWeight: 600 }} mr={1}>
      {`${title}: `}
    </Typography>
    <Typography variant="body">{value}</Typography>
  </ListItem>
)

const DataShower = ({ modal: { fields, filters, values } }) => {
  let filterValues = []

  if (fields && filters) {
    const selectsFields = fields.selects.map((s) => s.id.toString())
    for (const [key, value] of Object.entries(filters)) {
      if (selectsFields.includes(key)) {
        // It's select
        const field = fields.selects.find((f) => f.id === key)
        let v = ""
        if (value === -1) v = "Не выбрано"
        else {
          const option = field.options.find((op) => op.id === value)
          v = option?.title ? option.title : option.name
        }
        filterValues.push({
          title: field.title,
          value: v,
        })
      } else {
        // These checkboxs
        const field = fields.checkboxs.find((f) => f.id === key)
        filterValues.push({
          title: field.title,
          value: value ? "Да" : "Нет",
        })
      }
    }
  }

  return (
    <List sx={{ maxHeight: "70vh", overflow: "auto" }}>
      {filterValues &&
        filterValues.map(({ title, value }) => (
          <DataShowerItem key={title} title={title} value={value} />
        ))}
      <DataShowerItem title={"Текст"} value={values.text} />
    </List>
  )
}

const sendMailListRequest = async (headers, showSnackbar, data) => {
  let formData = new FormData()
  formData.append("text", data.values.text)
  formData.append("sender_phone_number", data.values.phone)
  if (data.values.image?.length && data.values.image.length)
    formData.append("image", data.values.image[0])
  if (data.values.file?.length && data.values.file.length)
    formData.append("file", data.values.file[0])
  if (data.values.exclude_file?.length && data.values.exclude_file.length)
    formData.append("exclude_file", data.values.file[0])
  let params = {
    start_date: data.startDate.format("YYYY-MM-DD"),
    end_date: data.endDate.format("YYYY-MM-DD"),
    "results[]": [],
  }
  if (data.fields && data.filters) {
    const selectsFields = data.fields.selects.map((s) => s.id.toString())
    for (const [key, value] of Object.entries(data.filters)) {
      if (selectsFields.includes(key)) {
        if (value && value !== -1) params[key.toString()] = value
      } else {
        if (value) params["results[]"].push(key)
      }
    }
  }

  const res = await axiosInstance
    .post(process.env.REACT_APP_API_URL + data.url, formData, {
      headers: headers,
      params: params,
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      showSnackbar("Не удалось отправить рассылку")
      return false
    })

  if (!res.status === 200) showSnackbar("Не удалось отправить рассылку")
  return res
}

export default function ConfirmModal({ modal, onClose, size }) {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const [data, setData] = useState(false)

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader({
        "Content-Type": "multipart/form-data",
      })
      if (await sendMailListRequest(headers, showSnackbar, data)) onClose()
    }
    if (data) load()
  }, [data])

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} />
      <DataShower modal={modal} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setData(modal)}
          color={"primary"}
          size={size}
          variant={"contained"}
        >
          Отправить
        </Button>
        <Button
          onClick={onClose}
          color={"secondary"}
          size={size}
          variant={"contained"}
        >
          Отмена
        </Button>
      </Box>
    </Modal>
  )
}
