import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import {
  Grid,
  Typography,
  IconButton,
  Hidden,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material"
import Profile from "./Profile"
import { useSidebarContext } from "../../hocs/SidebarProvider"
import { MenuOutlined } from "@mui/icons-material"

export default function Header({
  startDate = false,
  setStartDate,
  endDate = false,
  setEndDate,
  header = false,
}) {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const size = isSmScreen ? "small" : "medium"
  const { onClick } = useSidebarContext()
  return (
    <Grid
      display={"flex"}
      container
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexFlow: "row nowrap",
      }}
      p={isSmScreen && 2}
    >
      <Hidden smUp>
        <IconButton onClick={onClick}>
          <MenuOutlined />
        </IconButton>
      </Hidden>
      {header && (
        <Grid item>
          <Typography variant="h5" sx={{ margin: 0, padding: 0 }}>
            {header}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          pl={isSmScreen && 2}
          spacing={2}
          display={"flex"}
          justifyContent={"start"}
        >
          {startDate && (
            <Grid item>
              <DatePicker
                label="Дата начала"
                value={startDate}
                onChange={(v) => setStartDate(v)}
                slotProps={{ textField: { size: size } }}
              />
            </Grid>
          )}
          {endDate && (
            <Grid item>
              <DatePicker
                label="Дата окончания"
                value={endDate}
                onChange={(v) => setEndDate(v)}
                slotProps={{ textField: { size: size } }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Profile />
      </Grid>
    </Grid>
  )
}
