import React from "react"
import Modal from "../../../components/Modal"
import { useTheme } from "@emotion/react"
import { useMediaQuery } from "@mui/material"

export default function FileModal({ params, onClose, children }) {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Modal onClose={onClose} maxWidth={isSmScreen ? "100%" : "500px"}>
      {children}
    </Modal>
  )
}
