import React from "react"
import { Skeleton } from "@mui/material"
import Card from "./Card"
import StatsProgress from "../../../components/StatsProgress"
import useFeedback from "../hooks/useFeedback"

export default function Feedback({ startDate, endDate, department }) {
  const { feedback } = useFeedback(startDate, endDate, department)
  return (
    <Card
      title={`Обратная связь: ${feedback?.feedback?.count || "-"}, ${
        feedback?.feedback?.rate || "-"
      }%`}
    >
      {feedback ? (
        <>
          <StatsProgress
            color={"purple"}
            title={"Довольных клиентов"}
            value={feedback?.positive_feedback.count || "-"}
            valuePer={feedback?.positive_feedback.rate || 0}
          />
          <StatsProgress
            color={"purple"}
            title={"Есть замечания"}
            value={feedback?.not_ok_feedback.count || "-"}
            valuePer={feedback?.not_ok_feedback.rate || 0}
          />
          <StatsProgress
            color={"purple"}
            title={"Замечания критичны"}
            value={feedback?.critical_feedback.count || "-"}
            valuePer={feedback?.critical_feedback.rate || 0}
          />
        </>
      ) : (
        <>
          <Skeleton sx={{ width: "100%" }} height={50} />
          <Skeleton sx={{ width: "100%" }} height={50} />
          <Skeleton sx={{ width: "100%" }} height={50} />
        </>
      )}
    </Card>
  )
}
