import React from "react"
import { Button, Box, IconButton, Typography } from "@mui/material"
import Popup from "reactjs-popup"
import { useAuthContext } from "../../../hocs/AuthProvider"
import ava from '../../../assets/images/user.png'

export default function Profile(){
  const { user, signOut} = useAuthContext()

  return (
    <Box sx={{}}>
      <Popup
        trigger={open => (
          <IconButton sx={{width: '60px', height: '60px'}}>
            <img src={ava} style={{width: '100%', height: '100%'}}/>
          </IconButton>
        )}
        position={'bottom right'}
        closeOnDocumentClick
      >
        <Box p={2} sx={{background: '#fff', borderRadius: '5px', boxShadow: '0px 10px 60px rgba(226, 236, 249, 0.5)'}}>
          <Typography>{user.username}</Typography>
          <Button 
            color="secondary" 
            onClick={signOut}
          >
            Выйти
          </Button>
        </Box>
      </Popup>
    </Box>
  )
}