import React from "react"
import { Skeleton, Typography } from "@mui/material"
import Card from "./Card"
import useTasks from "../hooks/useTasks"

export default function Tasks({ startDate, endDate }) {
  const { tasks } = useTasks(startDate, endDate)
  return (
    <Card>
      {tasks ? (
        <>
          <Typography fontWeight={600}>{`Всего ушедших думать: ${
            tasks?.calls?.think || "-"
          } (${tasks?.calls?.think_rate || "-"})`}</Typography>
          <Typography>{`Им позвонили: ${tasks?.repeat_calls?.count || "-"} (${
            tasks?.repeat_calls?.rate || "-"
          }%)`}</Typography>
          <Typography>{`Успешных: ${
            tasks?.succeed_repeat_calls?.count || "-"
          } (${tasks?.succeed_repeat_calls?.rate || "-"}%)`}</Typography>
        </>
      ) : (
        <>
          <Skeleton sx={{ width: "100%" }} />
          <Skeleton sx={{ width: "100%" }} />
          <Skeleton sx={{ width: "100%" }} />
        </>
      )}
    </Card>
  )
}
