import React, { useState } from "react"
import { Controller } from "react-hook-form"
import closeIcon from "../../../assets/images/close.png"
import {
  Box,
  Button,
  FormControl,
  TextareaAutosize,
  Typography,
  IconButton,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Grid,
} from "@mui/material"
import {
  AttachFileOutlined,
  DeleteOutline,
  LibraryBooksOutlined,
  SendOutlined,
} from "@mui/icons-material"
import FileModal from "../FileModal"
import usePhones from "../../../hooks/usePhones"

const ModalHeader = ({ onClose, header }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{header}</Typography>
      <IconButton
        sx={{
          width: "40px",
          padding: "7px",
          marginRight: "-7px",
          height: "40px",
        }}
        onClick={onClose}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={closeIcon}
          alt="close"
        />
      </IconButton>
    </Box>
  )
}

const ImageModalComponent = ({
  fileModal,
  getValues,
  size,
  register,
  resetField,
  setFileModal,
}) => {
  const onClose = () => setFileModal(false)
  return (
    <FileModal modal={fileModal} size={size} onClose={onClose}>
      <ModalHeader onClose={onClose} header={"Прикрепить изображение"} />
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
        }}
      >
        <Input
          type="file"
          {...register("image")}
          variant={"contained"}
          startAdornment={
            <IconButton onClick={() => resetField("image")}>
              <DeleteOutline />
            </IconButton>
          }
          inputProps={{
            accept: ["image/*"],
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color={"secondary"}
            onClick={onClose}
            size={size}
          >
            Отмена
          </Button>
          <Button color={"primary"} onClick={onClose} variant="contained">
            Ок
          </Button>
        </Box>
      </Box>
    </FileModal>
  )
}

// const FileModalComponent = ({
//   fileModal,
//   getValues,
//   size,
//   register,
//   resetField,
//   setFileModal,
// }) => {
//   const onClose = () => setFileModal(false)
//   return (
//     <FileModal modal={fileModal} size={size} onClose={onClose}>
//       <ModalHeader onClose={onClose} header={"Загрузить файл номеров"} />
//       <Box
//         sx={{
//           display: "flex",
//           flexFlow: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           gap: "15px",
//         }}
//       >
//         <Typography>
//           Используйте файлы следущих форматов:{" "}
//           <a
//             style={{ textDecoration: "underline" }}
//             download={"examples.csv"}
//             href={process.env.PUBLIC_URL + "/phone_examples.csv"}
//           >
//             csv
//           </a>
//           , xlsx
//         </Typography>
//         <InputLabel sx={{ width: "100%" }}>
//           Загрузить номера рассылки
//         </InputLabel>
//         <Input
//           type="file"
//           {...register("file")}
//           variant={"contained"}
//           startAdornment={
//             <IconButton onClick={() => resetField("file")}>
//               <DeleteOutline />
//             </IconButton>
//           }
//           inputProps={{
//             accept: [
//               "text/csv",
//               "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//             ],
//           }}
//         />
//         <InputLabel sx={{ width: "100%" }}>
//           Загрузить номера исключений
//         </InputLabel>
//         <Input
//           type="file"
//           {...register("exclude_file")}
//           variant={"contained"}
//           startAdornment={
//             <IconButton onClick={() => resetField("exclude_file")}>
//               <DeleteOutline />
//             </IconButton>
//           }
//           inputProps={{
//             accept: [
//               "text/csv",
//               "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//             ],
//           }}
//         />
//         <Box
//           sx={{
//             display: "flex",
//             flexFlow: "row nowrap",
//             justifyContent: "space-between",
//             alignItems: "center",
//             width: "100%",
//           }}
//         >
//           <Button
//             variant="contained"
//             color={"secondary"}
//             onClick={onClose}
//             size={size}
//           >
//             Отмена
//           </Button>
//           <Button color={"primary"} onClick={onClose} variant="contained">
//             Ок
//           </Button>
//         </Box>
//       </Box>
//     </FileModal>
//   )
// }

export default function SendMailList({
  url,
  startDate,
  endDate,
  size,
  setModal,
  setTemplateModal,
  filters = false,
  fields = false,
  mailForm: {
    getValues,
    resetField,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  },
}) {
  const [imageModal, setImageModal] = useState()
  const { phones } = usePhones()
  const sendMailList = (values) => {
    const data = {
      filters: filters,
      fields: fields,
      values: values,
      startDate: startDate,
      endDate: endDate,
      url: url,
    }
    setModal(data)
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        padding: 3,
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
        overflow: "hidden",
      }}
    >
      <form
        onSubmit={handleSubmit(sendMailList)}
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "auto",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6" sx={{ width: "100%", fontWeight: 600 }}>
          Рассылка
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            boxSizing: "border-box",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="span" sx={{ fontWeight: 600 }} pb={1}>
            {errors?.text ? (
              <Typography color={"secondary"} m={0}>
                {errors?.text?.message}
              </Typography>
            ) : (
              "Текст рассылки"
            )}
          </Typography>
          <TextareaAutosize
            minRows={4}
            maxRows={10}
            {...register("text", {
              required: "Текст не может быть пустым",
            })}
            disableNestedContext={false}
            style={{
              width: "50%",
              resize: "vertical",
              width: "100%",
              overflow: "hidden",
              background: "#FAFBFF",
              border: "2px solid #ebebef",
              borderRadius: "15px",
              boxSizing: "border-box",
              outline: "none",
              padding: "10px",
              margin: "10px 0",
            }}
          />
        </Box>
        <FormControl sx={{ marginY: 1 }}>
          <InputLabel
            size={size}
            variant={"outlined"}
            id={`phone-select-label-id`}
          >
            {errors?.phone ? (
              <Typography color={"secondary"}>
                {errors.phone.message}
              </Typography>
            ) : (
              "Телефон рассылки"
            )}
          </InputLabel>
          <Controller
            defaultValue={""}
            render={({ field }) => (
              <Select
                label={
                  errors?.phone ? errors.phone.message : "Телефон рассылки"
                }
                sx={{ width: "250px", boxSizing: "border-box" }}
                variant="outlined"
                size={size}
                labelId={`phone-select-label-id`}
                {...field}
              >
                <MenuItem value={""}>Не выбрано</MenuItem>
                {phones &&
                  phones.configs.map((p) => (
                    <MenuItem value={p.phone_number} key={p.id}>
                      {`${p.phone_number}`}
                      {p.name && ` (${p.name})`}
                    </MenuItem>
                  ))}
              </Select>
            )}
            name={"phone"}
            rules={{
              required: "Выберите телефон рассылки",
            }}
            control={control}
          />
        </FormControl>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "15px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <IconButton onClick={() => setImageModal(true)}>
                <AttachFileOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                size={size}
                variant="contained"
                color={"primary"}
                endIcon={<SendOutlined />}
                type="submit"
              >
                Отправить
              </Button>
            </Grid>
            <Grid item>
              <Button
                size={size}
                onClick={setTemplateModal}
                variant="contained"
                color={"blue"}
                endIcon={<LibraryBooksOutlined />}
              >
                Шаблоны сообщений
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ zIndex: imageModal ? 9999 : -1 }}>
            <ImageModalComponent
              size={size}
              fileModal={imageModal}
              getValues={getValues}
              setFileModal={setImageModal}
              register={register}
              resetField={resetField}
            />
          </Box>
        </Box>
      </form>
    </Box>
  )
}
