import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useSnackBarCustom } from "../../../hooks/useSnackbarCustom"
import { useAuthContext } from "../../../hocs/AuthProvider"

const getRequest = async (headers, showSnackbar, {startDate, endDate, department}) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    department: department
  })
  const res = await fetch(process.env.REACT_APP_API_URL + "main_page/calls/?" + params.toString(), {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить данные")
  return false
}


export default function useCalls(startDate=dayjs(new Date()), endDate=dayjs(new Date()), department=false){
  const { getAuthHeader } = useAuthContext()
  const {showSnackbar} = useSnackBarCustom()
  const [data, setData] = useState(false)

  useEffect(() => {
    setData(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const d = await getRequest(headers, showSnackbar, {startDate: startDate, endDate: endDate, department: department})
      setData(d)
    }
    load()
  }, [startDate, endDate])

  return { calls: data }
}