import React from "react"
import { Box } from "@mui/material"

export default function Modal({ onClose, maxWidth = "500px", children }) {
  return (
    <Box
      onClick={onClose}
      sx={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.4)",
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Box
        p={2}
        onClick={(e) => e.stopPropagation()}
        sx={{
          background: "#fff",
          borderRadius: "15px",
          maxWidth: maxWidth,
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
