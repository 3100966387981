import React from "react"
import { Box } from "@mui/material"
import { TabPanel } from "@mui/lab"

export default function CustomTabPanel({ children, value }) {
  return (
    <TabPanel
      value={value}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: 0,
        paddingTop: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </TabPanel>
  )
}
