import React, { createRef } from "react"
import {
  Box,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Pagination,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  ListItemText,
  ListItem,
  Toolbar,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useAuthContext } from "../../../hocs/AuthProvider"
import { useSnackbarContext } from "../../../hocs/SnackbarProvider"
import {
  AccountCircleOutlined,
  GarageOutlined,
  PersonAddAlt1Outlined,
  SearchOff,
  ExpandMore,
} from "@mui/icons-material"
import expandIcon from "../../../assets/images/expand.png"
import shrinkIcon from "../../../assets/images/shrink.png"
import SearchByPhone from "../../../components/SearchByPhone"

const getHighlightedText = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <>
      {parts.map((part) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span style={{ background: "yellow" }}>{part}</span>
        ) : (
          part
        )
      )}
    </>
  )
}

const saveOrder = async (headers, data, order, showSnackbar) => {
  let readyData = {
    processed: true,
    comment: data.comment,
    result_id: data.callStatus,
  }
  const res = await fetch(
    process.env.REACT_APP_API_URL + `feedback/${order.id}/`,
    {
      headers: headers,
      method: "PUT",
      body: JSON.stringify(readyData),
    }
  ).catch((e) => showSnackbar("Не удалось сохранить заказ"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }

  showSnackbar("Не удалось сохранить заказ")
  return false
}

const NotOrders = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
        gap: "10px",
      }}
    >
      <Typography variant="h6">Заказов не найдено</Typography>
      <SearchOff sx={{ width: "40px", height: "40px" }} />
    </Box>
  )
}

const ExpandTable = ({ fullTable, setFullTable }) => {
  return (
    <Box sx={{ boxSizing: "border-box", width: "100%", height: "40px" }}>
      <IconButton
        onClick={() => setFullTable(!fullTable)}
        sx={{ width: "35px", height: "35px" }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          alt="hide filter"
          src={fullTable ? shrinkIcon : expandIcon}
        />
      </IconButton>
    </Box>
  )
}

const Loading = () => {
  return (
    <Grid
      container
      sx={{ width: "100%", height: "100%", boxSizing: "border-box" }}
    >
      {[...Array(10).keys()].map((it) => (
        <Grid p={0} item key={it} sx={{ width: "100%", height: "250px" }}>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Skeleton sx={{ height: "100%" }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

const OrderInfo = ({ searchQuery, order, size }) => {
  const phone = order.order.customer.phone
  let phoneEl = phone
  if (phone.toString().includes(searchQuery)) {
    // Найдено совпадение с поисковым запросом
    phoneEl = getHighlightedText(phone, searchQuery)
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Grid container p={1} sx={{ width: "100%", height: "100%" }}>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <AccountCircleOutlined
            fontSize={size === "medium" ? "large" : "medium"}
          />
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {order.order.customer.name}
            </Typography>
            <Typography>
              <a href={`tel:${phone}`}>{phoneEl}</a>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <GarageOutlined fontSize={size === "medium" ? "large" : "medium"} />
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Филиал</Typography>
            <Typography>{order.order.department.title}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <PersonAddAlt1Outlined
            fontSize={size === "medium" ? "large" : "medium"}
          />
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Мастер приемщик</Typography>
            <Typography>{order.order.master.name}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Accordion variant="outlined" sx={{ backgroundColor: "inherit" }}>
        <AccordionSummary sx={{ width: "100%" }} expandIcon={<ExpandMore />}>
          <Grid container sx={{ width: "100%", heihgt: "100%" }}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography sx={{ fontWeight: 600 }}>Номер заказа</Typography>
              <Typography>{order.order.order_number}</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography sx={{ fontWeight: 600 }}>Дата заказа</Typography>
              <Typography>{order.order.date_time_string}</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography sx={{ fontWeight: 600 }}>Сумма заказа</Typography>
              <Typography>{order.order.services_amount}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ width: "100%", height: "100%" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="span" sx={{ fontWeight: 600 }}>
                {`Услуги на сумму: ${order.order.services_amount}`}
              </Typography>
              <List>
                {order.order.nomenclature["Услуги"].map((it, index) => (
                  <ListItemText key={index}>{it}</ListItemText>
                ))}
              </List>
            </Grid>
            {order.order.goods_nomenclature > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="span" sx={{ fontWeight: 600 }}>
                  {`Запчасти на сумму: ${order.order.goods_amount}`}
                </Typography>
                <List>
                  {order.order.nomenclature["Товары"].map((it, index) => (
                    <ListItemText key={index}>{it}</ListItemText>
                  ))}
                </List>
              </Grid>
            )}
            {order.order.customer_goods_nomenclature > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ color: "red" }}>
                <Typography variant="span" sx={{ fontWeight: 600 }}>
                  {`Запчасти клиента на сумму: ${order.order.customer_goods_amount}`}
                </Typography>
                <List>
                  {order.order.nomenclature["ТоварыКлиента"].map(
                    (it, index) => (
                      <ListItemText key={index}>{it}</ListItemText>
                    )
                  )}
                </List>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const OrderForm = ({
  order: { result_id, comment },
  fields,
  register,
  control,
  size,
  errors,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "15px",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "column",
        gap: "10px",
      }}
    >
      <FormControl sx={{ width: "100%", boxSizing: "border-box" }} size={size}>
        <InputLabel
          error={errors?.callStatus}
          variant={"outlined"}
          id={`status-call-order-card-select-label-id`}
        >
          Результат
        </InputLabel>
        <Controller
          // defaultValue={result_id || ''}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              label={"Результат"}
              sx={{ width: "100%", minWidth: "100%" }}
              variant="outlined"
              size={size}
              labelId={`status-call-order-card-select-label-id`}
              {...field}
              value={field.value || result_id || ""}
            >
              {fields.checkboxs.map((st) => (
                <MenuItem key={st.id} value={st.id}>
                  {st.title}
                </MenuItem>
              ))}
            </Select>
          )}
          name={"callStatus"}
          control={control}
        />
      </FormControl>
      <TextField
        sx={{ width: "100%" }}
        label="Комментарий"
        multiline
        {...register("comment", {
          value: comment,
        })}
        error={errors?.comment}
        helperText={errors?.comment?.message}
        size={size}
      />
    </Box>
  )
}

const Order = ({ searchQuery, order, orders, fields, setOrders, size }) => {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const {
    register,
    formState: { errors, defaultValues },
    control,
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" })

  const dontCallRequest = () => {
    const customerId = order.order.customer.id
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      fetch(process.env.REACT_APP_API_URL + `customers/do_not_call/`, {
        headers: h,
        method: "POST",
        body: JSON.stringify({
          id: customerId,
        }),
      })
        .then((r) => r.json())
        .then((res) => {
          const newOrders = orders.results.map((or) => {
            if (or.id === order.id) {
              return {
                ...order,
                order: {
                  ...order.order,
                  customer: {
                    ...order.order.customer,
                    do_not_call: true,
                  },
                },
              }
            }
            return or
          })
          setOrders({ ...orders, results: newOrders })
        })
    })
  }

  const save = (data) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      saveOrder(h, data, order, showSnackbar).then((res) => {
        if (res) {
          setOrders({
            ...orders,
            results: orders.results.map((or) => (or.id === res.id ? res : or)),
          })
          reset()
        }
      })
    })
  }

  const DoNotCall = order.order.customer.do_not_call

  return (
    <ListItem sx={{ width: "100%", boxSizing: "border-box", padding: 0 }}>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          background: DoNotCall
            ? "rgba(255, 0, 0, .2)"
            : order.processed
            ? "#eee"
            : "inherit",
          margin: "15px 0",
        }}
      >
        <form onSubmit={handleSubmit(save)}>
          <CardContent>
            <OrderInfo order={order} size={size} searchQuery={searchQuery} />
            <OrderForm
              order={order}
              register={register}
              control={control}
              errors={errors}
              size={size}
              fields={fields}
            />
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" size={size}>
              Сохранить
            </Button>
            <Toolbar title={"Клиент попросил не звонить ему"}>
              <Button
                disabled={DoNotCall}
                variant="contained"
                onClick={dontCallRequest}
                color={"secondary"}
                size={size}
              >
                Не звонить
              </Button>
            </Toolbar>
            {/* )} */}
          </CardActions>
        </form>
      </Card>
    </ListItem>
  )
}

const Content = ({
  orders,
  setOrders,
  filters,
  fields,
  page,
  setPage,
  size,
  perPage,
  setPerPage,
  fullTable,
  setFullTable,
}) => {
  const listRef = createRef()
  const scrollToTop = () => listRef.current && listRef.current.scrollIntoView()

  const onChangePage = (e, p) => {
    setPage(p)
    scrollToTop()
    setOrders(false)
  }
  const onChangePerPage = (e) => {
    setPerPage(e.target.value)
    setPage(1)
    setOrders(false)
    scrollToTop()
  }

  if (!orders.count) return <NotOrders />
  return (
    <>
      <Select value={perPage} onChange={onChangePerPage} size={"small"}>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>
      <List ref={listRef} sx={{ width: "100%" }}>
        {orders.results.map((or) => (
          <Order
            key={or.id}
            searchQuery={filters?.phone || false}
            order={or}
            orders={orders}
            setOrders={setOrders}
            size={size}
            fields={fields}
          />
        ))}
      </List>
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
        }}
      >
        <Pagination
          page={page}
          onChange={onChangePage}
          count={Math.ceil(orders.count / perPage)}
          align="left"
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </>
  )
}

export default function Orders({
  orders,
  setOrders,
  fields,
  filters,
  setFilters,
  page,
  setPage,
  perPage,
  setPerPage,
  fullTable,
  setFullTable,
}) {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const size = isSmScreen ? "small" : "medium"

  return (
    <Box
      p={2}
      m={0}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {setFullTable && (
          <ExpandTable fullTable={fullTable} setFullTable={setFullTable} />
        )}
        <SearchByPhone filters={filters} setFilters={setFilters} />
      </Box>
      <Box
        p={isSmScreen ? 2 : 4}
        sx={{
          paddingTop: 0,
          width: "100%",
          height: "calc(100% - 40px)",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {orders ? (
          <Content
            orders={orders}
            setOrders={setOrders}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            filters={filters}
            size={size}
            fullTable={fullTable}
            setFullTable={setFullTable}
            fields={fields}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  )
}
