import React, { createContext, useContext } from "react"
import { useAuth } from "../hooks/useAuth"
import { useAuthNew } from "../hooks/useAuthNew"

const AuthContext = createContext()
const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const auth = useAuthNew()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export { useAuthContext, AuthProvider }
