import React, { useState } from "react"
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from "@mui/material"
import MailListFilter from "../MailListFilter"
import SendMailList from "../SendMailList"
import { useForm } from "react-hook-form"
import TemplateModal from "../TemplatesModal"
import { DeleteOutline } from "@mui/icons-material"

const UploadFile = ({
  form: {
    register,
    resetField,
    formState: { errors },
  },
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ width: "100%", fontWeight: 600 }}>
        Загрузить номера
      </Typography>
      <Typography>
        Используйте файлы следущих форматов:{" "}
        <a
          style={{ textDecoration: "underline" }}
          download={"examples.csv"}
          href={process.env.PUBLIC_URL + "/phone_examples.csv"}
        >
          csv
        </a>
        , xlsx
      </Typography>
      <InputLabel sx={{ width: "100%" }} error={errors?.file}>
        {errors?.file?.message
          ? errors.file.message
          : "Загрузить номера рассылки"}
      </InputLabel>
      <Input
        type="file"
        {...register("file", {
          required: "Обязательное поле",
        })}
        variant={"outlained"}
        startAdornment={
          <IconButton onClick={() => resetField("file")}>
            <DeleteOutline />
          </IconButton>
        }
        inputProps={{
          accept: [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
        }}
      />
      <InputLabel sx={{ width: "100%" }}>
        Загрузить номера исключений
      </InputLabel>
      <Input
        type="file"
        {...register("exclude_file")}
        variant={"contained"}
        startAdornment={
          <IconButton onClick={() => resetField("exclude_file")}>
            <DeleteOutline />
          </IconButton>
        }
        inputProps={{
          accept: [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
        }}
      />
    </Box>
  )
}

export default function File({ startDate, endDate, setModal, size }) {
  const form = useForm({ mode: "onBlur" })
  const [templateModal, setTemplateModal] = useState(false)

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        boxSizing: "border-box",
      }}
    >
      {templateModal && (
        <TemplateModal
          onClose={() => setTemplateModal(false)}
          setText={(t) => form.setValue("text", t)}
        />
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
        <UploadFile form={form} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
        <SendMailList
          url={"calls/send_from_csv/"}
          startDate={startDate}
          endDate={endDate}
          mailForm={form}
          size={size}
          setModal={setModal}
          setTemplateModal={setTemplateModal}
        />
      </Grid>
    </Grid>
  )
}
