import React from "react"
import { Box, Typography } from "@mui/material"
import { useOutletContext } from "react-router-dom"

export default function Card({ children, title = false }) {
  const pagePadding = useOutletContext()
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        background: "#fff",
        borderRadius: "15px",
        boxSizing: "border-box",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        padding: pagePadding,
      }}
    >
      {title ? (
        <Typography sx={{ fontWeight: 6000 }} variant="h6">
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {children}
    </Box>
  )
}
