import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import dayjs from "dayjs"
import CallModal from "../../components/CallModal"
import { Box } from "@mui/material"
import useCalls from "../../hooks/useCalls"
import useFilters from "../../hooks/useFilters"
import Filter from "../../components/Filter"
import CallsTable from "../../components/CallsTable"
import { useOutletContext } from "react-router-dom"

export default function Analytics() {
  const { fields, filters, setFilters } = useFilters("selects/calls/")
  const [modalCall, setModalCall] = useState(false)
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [fullTable, setFullTable] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const { calls, setCalls } = useCalls(
    filters,
    fields,
    startDate,
    endDate,
    page,
    perPage
  )
  const pagePadding = useOutletContext()

  useEffect(() => setPage(0), [filters, startDate, endDate])

  return (
    <>
      <CallModal
        calls={calls}
        setCalls={setCalls}
        fields={fields}
        onClose={() => setModalCall(false)}
        call={modalCall}
      />
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          gap: pagePadding,
        }}
      >
        <Header
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Box
          sx={{
            display: fullTable ? "none" : "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxSizing: "border-box",
            gap: "inherit",
          }}
        >
          <Filter
            fields={fields}
            onApplyFilter={(d) => setFilters(d)}
            onResetFilter={() => setFilters(false)}
            count={calls?.count ? calls.count : 0}
            checkboxsStats={calls.stats}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
            borderRadius: "15px",
          }}
        >
          <CallsTable
            filters={filters}
            setFilters={setFilters}
            page={page}
            setPage={setPage}
            setModalCall={setModalCall}
            calls={calls}
            setCalls={setCalls}
            fullTable={fullTable}
            setFullTalbe={setFullTable}
            rows={perPage}
            setRows={setPerPage}
          />
        </Box>
      </Box>
    </>
  )
}
