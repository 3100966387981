import React from "react"
import { Box } from "@mui/material"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

export default function RepeatCalls({ width, height, data }) {
  if (!data || !data?.tasks) return <></>
  if (data.tasks.total === 0) return <></>

  const readyData = []

  for (const [key, value] of Object.entries(data.tasks)) {
    console.log(`${key}: ${value}`)
    if (key !== "total") {
      readyData.push({
        name: key.slice(0, 5),
        Успех: value.success,
        Провал: value.fail,
      })
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BarChart
        width={width}
        height={height}
        data={readyData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Успех" stackId="a" fill="#16C099" />
        <Bar dataKey="Провал" stackId="a" fill="#A82336" />
      </BarChart>
    </Box>
  )
}
