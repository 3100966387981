import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { ReactComponent as LogoSvg } from "../../../assets/images/logo.svg"
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material"
import { Navigate, Link } from "react-router-dom"
import { useAuthContext } from "../../../hocs/AuthProvider"
import { Visibility, VisibilityOff } from "@mui/icons-material"

export default function SignUp({}) {
  const [redirect, setRedirect] = useState(false)
  const [data, setData] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onBlur",
  })

  const { signUp } = useAuthContext()

  useEffect(() => {
    const createUser = async () => {
      const res = await signUp(data)
      if (res?.error) {
        if (res.error?.username)
          setError("login", { type: "custom", message: res.error.username })
        if (res.error?.email)
          setError("email", { type: "custom", message: res.error.email })
        if (res.error?.password)
          setError("password", { type: "custom", message: res.error.password })
      } else if (res?.user) {
        // Success user create
        setRedirect("/signin")
        reset()
      }
    }
    if (data) createUser()
  }, [data])

  const submitHandle = (v) => {
    if (v.password === v.repeatPassword) return setData(v)
    setError("repeatPassword", {
      type: "custom",
      message: "Пароли не совпадают",
    })
  }

  if (redirect) return <Navigate to={redirect} replace />

  return (
    <form
      noValidate
      onSubmit={handleSubmit(submitHandle)}
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        padding: "20px",
        gap: "30px",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "start",
          margin: 0,
        }}
      >
        <LogoSvg />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "0 30px",
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          label="Логин"
          {...register("login", {
            required: "Введите логин",
            minLength: {
              value: 4,
              message: "Минимум 4 символа",
            },
            maxLength: {
              value: 24,
              message: "Максимум 24 символа",
            },
          })}
          variant="outlined"
          error={errors?.login ? true : false}
          helperText={errors?.login?.message}
        />
        <TextField
          sx={{ width: "100%" }}
          label="Email"
          type="email"
          {...register("email", {
            required: "Введите email",
          })}
          variant="outlined"
          error={errors?.email ? true : false}
          helperText={errors?.email?.message}
        />
        <TextField
          sx={{ width: "100%" }}
          type={showPassword ? "text" : "password"}
          label="Пароль"
          {...register("password", {
            required: "Введите пароль",
            minLength: {
              value: 8,
              message: "Минимум 8 символов",
            },
            maxLength: {
              value: 128,
              message: "Максимум 128 символов",
            },
          })}
          variant="outlined"
          error={errors?.password ? true : false}
          helperText={errors?.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: "100%" }}
          type="password"
          label="Повторите пароль"
          {...register("repeatPassword", {
            required: "Повторите пароль",
            minLength: {
              value: 8,
              message: "Минимум 8 символов",
            },
            maxLength: {
              value: 128,
              message: "Максимум 128 символов",
            },
          })}
          variant="outlined"
          error={errors?.repeatPassword ? true : false}
          helperText={errors?.repeatPassword?.message}
        />
        <Button type="submit" variant="contained">
          Зарегистрироваться
        </Button>
      </Box>
      <Link to={"/signin"}>Войти</Link>
    </form>
  )
}
