import { useState } from "react"

export const useAuth = () => {
  const [user, setUser] = useState(null)

  const isTokenExpired = (token) => {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    return Math.floor(new Date().getTime() / 1000) + 10 >= expiry
  }

  const updateAccessToken = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "auth/token/refresh/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      )
      if (res.ok) {
        const resData = await res.json()
        setUser({ ...user, access: resData.access })
        return resData.access
      }
    } catch(e) {
      console.error(e)
      return false
    }
    return false
  }

  const getAuthHeader = async (headers = {}) => {
    let accessToken = user.access
    if (!accessToken || isTokenExpired(accessToken)) {
      const token = await updateAccessToken()
      if (!token) {
        signOut()
        return false
      } else {
        Object.assign(headers, { Authorization: `Bearer ${token}` })
      }
    }
    Object.assign(headers, { Authorization: `Bearer ${accessToken}` })
    return headers
  }

  const getProfile = async (token) => {
    const res = await fetch(process.env.REACT_APP_API_URL + "auth/profile/", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    })
    if (res.ok) return await res.json()
    return false
  }

  const signIn = async (username, password) => {
    const res = await fetch(process.env.REACT_APP_API_URL + "token/", {
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
      },
    })

    if (!res.ok) {
      signOut()
      return false
    }

    const tokens = await res.json()
    const profile = await getProfile(tokens.access)

    if (!profile) return false

    const userObj = {
      id: profile?.id ? profile.id : undefined,
      username: profile.user.username,
      email: profile.user.email,
      access: tokens.access,
      refresh: tokens.refresh,
      type: profile.user?.type ? profile.user.type :  "OWNER",
      // type: "OPERATOR",
    }
    setUser(userObj)
    return userObj
  }

  const restoreSignIn = async () => {
    // Пытаемся зайти в пользователя, если в cookies есть refresh token
    const token = await updateAccessToken()
    if (token) {
      console.debug('This')
      const profile = await getProfile(token)
      const user = {
        ...user,
        access: token,
        id: profile?.id ? profile.id : undefined,
        username: profile.user.username,
        email: profile.user.email,
      }
      setUser(user)
      return user
    }
    return false
  }

  const signUp = async (d) => {
    const data = {
      username: d.login,
      email: d.email,
      password: d.password,
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "auth/profile/", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
      },
    })
    const resData = await res.json()
    // if (res.ok && resData?.user){}
    return resData
  }

  const signOut = () => {
    fetch(process.env.REACT_APP_API_URL + "auth/token/blacklist/", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((r) => {
        setUser("logout")
        return r.json()
      })
      .then((res) => setUser("logout"))
      .catch((e) => {
        console.error(e)
        setUser("logout")
      })
  }

  return { user, getAuthHeader, signIn, signUp, signOut, restoreSignIn }
}
