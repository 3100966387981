import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"

const saveConfigRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(
    process.env.REACT_APP_API_URL + `tasks/${data.id}/update/`,
    {
      headers: headers,
      method: "PATCH",
      body: JSON.stringify(data),
    }
  ).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    showSnackbar("Задача сохранена")
    return resData
  }
  showSnackbar("Не удалось сохранить задачу")
  return false
}

const getConfigRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "company/config/", {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить настройки")
  return false
}


export default function useConfig() {
  const [config, setConfig] = useState(false)
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const config = await getConfigRequest(headers, showSnackbar)
      console.log(config)
      setConfig(config)
    }
    if (!config) load()
  }, [])


  const saveConfig = (data) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      saveConfigRequest(data, h, showSnackbar).then((d) => {
        if (d) {
          setConfig(config)
        }
      })
    })
  }

  return {
    config, saveConfig
  }
}
