import React from "react"
import { Box } from "@mui/material"
import { useOutletContext } from "react-router-dom"

export default function Profile() {
  const pagePadding = useOutletContext()
  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      sx={{
        gap: pagePadding,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box>Profile</Box>
    </Box>
  )
}
