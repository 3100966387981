import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getPhonesRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "send_messages/config/", {
    headers: headers,
    method: "GET",
  }).catch((e) => showSnackbar("Не удалось получить телефоны"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить телефоны")
  return false
}

export default function usePhones() {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [phones, setPhones] = useState(false)

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const phones = await getPhonesRequest(headers, showSnackbar)
      setPhones(phones)
    }
    load()
  }, [])

  return { phones }
}
