import React, { useState } from "react"
import Modal from "../../../components/Modal"
import {
  Typography,
  IconButton,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextareaAutosize,
  ButtonGroup,
  TextField,
} from "@mui/material"
import closeIcon from "../../../assets/images/close.png"
import { ExpandMoreOutlined } from "@mui/icons-material"
import useTemplates from "../../../hooks/useTemplates"

const ModalHeader = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{`Выберите шаблон`}</Typography>
      <IconButton
        sx={{
          width: "40px",
          padding: "7px",
          marginRight: "-7px",
          height: "40px",
        }}
        onClick={onClose}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={closeIcon}
          alt="close"
        />
      </IconButton>
    </Box>
  )
}

const CreateForm = ({ createForm, setCreateForm, createTemplate }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        onChange={(e) =>
          setCreateForm({ ...createForm, title: e.target.value })
        }
        sx={{ width: "100%" }}
        label="Заголовок"
      />
      <TextareaAutosize
        onChange={(e) => setCreateForm({ ...createForm, text: e.target.value })}
        maxRows={5}
        minRows={5}
        style={{ width: "98%" }}
      />
      <ButtonGroup variant="contained">
        <Button onClick={() => createTemplate(createForm)} color="primary">
          Создать
        </Button>
        <Button onClick={() => setCreateForm(false)} color="secondary">
          Отменить
        </Button>
      </ButtonGroup>
    </Box>
  )
}

const TemplateItem = ({
  template: { id, title, children = false, text = false },
  saveTemplate,
  createTemplate,
  setText,
}) => {
  const [editText, setEditText] = useState(text)
  const [createForm, setCreateForm] = useState(false)
  const truncate = (input) =>
    input.length > 32 ? `${input.substring(0, 32)}...` : input

  return (
    <Accordion sx={{ minWidth: "400px" }}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Typography>{truncate(title)}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: "5px" }}>
        {children.length ? (
          <>
            {children.map((t) => (
              <TemplateItem
                key={t.id}
                template={t}
                saveTemplate={saveTemplate}
                createTemplate={createTemplate}
                setText={setText}
              />
            ))}
            <Button
              color={"other"}
              disabled={createForm}
              variant="contained"
              onClick={() => {
                setCreateForm({
                  title: "",
                  text: "",
                  parent: id,
                })
              }}
            >
              Создать
            </Button>
            {createForm && (
              <CreateForm
                createForm={createForm}
                setCreateForm={setCreateForm}
                createTemplate={createTemplate}
              />
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TextareaAutosize
                maxRows={5}
                minRows={5}
                style={{ width: "98%" }}
                onChange={(e) => setEditText(e.target.value)}
              >
                {text}
              </TextareaAutosize>
              <ButtonGroup variant={"contained"}>
                <Button
                  color={"other"}
                  disabled={createForm}
                  onClick={() => {
                    setCreateForm({
                      title: "",
                      text: "",
                      parent: id,
                    })
                  }}
                >
                  Создать
                </Button>
                <Button onClick={() => setText(text)}>Использовать</Button>
                <Button
                  onClick={() => {
                    saveTemplate({
                      text: editText,
                      id: id,
                    })
                    setEditText(text)
                  }}
                  color={"secondary"}
                  disabled={text === editText}
                >
                  Сохранить
                </Button>
              </ButtonGroup>
              {createForm && (
                <CreateForm
                  createForm={createForm}
                  setCreateForm={setCreateForm}
                  createTemplate={createTemplate}
                />
              )}
            </Box>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default function TemplateModal({ onClose, setText }) {
  const { templates, saveTemplate, createTemplate } = useTemplates()
  const setTextWrapper = (text) => {
    onClose()
    setText(text)
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} />
      {templates &&
        templates.map((t) => (
          <TemplateItem
            key={t.id}
            saveTemplate={saveTemplate}
            createTemplate={createTemplate}
            template={t}
            setText={setTextWrapper}
          />
        ))}
      {/* <form>
        <TextField label="Заголовок" />
        <TextareaAutosize
          maxRows={5}
          minRows={5}
          style={{ width: "98%" }}
          onChange={(e) => {}}
        ></TextareaAutosize>
      </form> */}
    </Modal>
  )
}
