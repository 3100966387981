import React from "react"
import { Box, Typography } from "@mui/material"
import StatsProgress from "../../../components/StatsProgress"

export default function Feedback({ data }) {
  if (!data) return <></>
  let readyData = []
  for (const [key, value] of Object.entries(data)) {
    if (value.title) {
      if (value.color) {
        if (value.percentage) {
          readyData.push({
            title: value.title,
            color: value.color.toLowerCase(),
            percentage: value.percentage,
          })
        }
      }
    }
  }

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography variant="span" sx={{ width: "100%" }}>
        Всего реализации:{" "}
        <Typography display="inline" variant="h6" sx={{ fontWeight: 600 }}>
          {data.total}
        </Typography>
      </Typography>
      <br></br>
      <Typography variant="span" sx={{ width: "100%" }}>
        Обработано клиентов:{" "}
        <Typography display="inline" variant="h6" sx={{ fontWeight: 600 }}>
          {data.total_good}
        </Typography>
      </Typography>
      {readyData.map((d) => (
        <StatsProgress
          key={d}
          title={d.title}
          value={d.count}
          valuePer={d.percentage ? d.percentage : 0}
          color={d.color}
        />
      ))}
    </Box>
  )
}
