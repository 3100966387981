import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getAnalyticsRequest = async (headers, showSnackbar, {startDate, endDate}) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    // page: page + 1,
    // page_size: perPage,
  })
  const res = await fetch(process.env.REACT_APP_API_URL + "analytics/?" + params.toString(), {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить данные")
  return false
}


export default function useStats(startDate=dayjs(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), endDate=dayjs(new Date())){
  const { getAuthHeader } = useAuthContext()
  const {showSnackbar} = useSnackBarCustom()
  const [analytics, setAnalytics] = useState(false)

  useEffect(() => {
    setAnalytics(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const analytics = await getAnalyticsRequest(headers, showSnackbar, {startDate: startDate, endDate: endDate})
      setAnalytics(analytics)
    }
    load()
  }, [startDate, endDate])

  return { analytics }
}