import React, { createContext, useContext } from "react"
import { useSnackBarCustom } from "../hooks/useSnackbarCustom"
import { Snackbar, Button, Slide, useMediaQuery, useTheme } from "@mui/material"

const SnackbarContext = createContext()
const useSnackbarContext = () => useContext(SnackbarContext)

function TransitionRight(props) {
  return <Slide {...props} direction="right" />
}

const SnackbarProvider = ({ children }) => {
  const customSnackbar = useSnackBarCustom()
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <SnackbarContext.Provider value={customSnackbar}>
      {children}
      <Snackbar
        sx={{ marginBottom: isSmScreen ? "60px" : 0, zIndex: 6 }}
        TransitionComponent={TransitionRight}
        {...customSnackbar.props}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        action={
          <Button sx={{ color: "#fff" }} onClick={customSnackbar.onClose}>
            Hide
          </Button>
        }
        ContentProps={{ sx: { backgroundColor: "#f46d75", color: "#000" } }}
        color="secondary"
        message={customSnackbar.message}
      />
    </SnackbarContext.Provider>
  )
}

export { useSnackbarContext, SnackbarProvider }
