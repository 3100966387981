import React, { useState } from "react"
import { Outlet } from "react-router"
import Sidebar from "./Sidebar"
import { Box, useMediaQuery, useTheme } from "@mui/material"

// export default function LkLayout(){
// const theme = useTheme()
// const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'))
// const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
// const sidebarWidth = isSmScreen ? '100%' : (isMdScreen ? '80px' : '250px')
// const sidebarHeight = isSmScreen ? '60px' : '100%'
// const pageHeight = isSmScreen ? 'calc(100% - 60px)' : '100%'
// const pageWidth = isSmScreen ? '100%' : `calc(100% - ${sidebarWidth})`
// const pagePadding = isSmScreen ? '10px' : (isMdScreen ? '20px' : '30px')

//   return (
//     <Box
//       flexDirection={ isSmScreen ? 'column-reverse' : 'row' }
//       sx={{display: 'flex', width: '100%', height: '100%', overflow: 'hidden', boxSizing: 'border-box', zIndex: 3}}
//     >
//       <Box sx={{height: sidebarHeight, width: sidebarWidth, zIndex: 'inherit'}}>
//         <Sidebar />
//       </Box>
//       <Box sx={{backgroundColor: '#FAFBFF', boxSizing: 'border-box', height: pageHeight, width: pageWidth, padding: pagePadding}}>
//         <Outlet context={pagePadding}/>
//       </Box>
//     </Box>
//   )
// }

export default function LkLayout() {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"))
  const sidebarWidth = isSmScreen ? "100%" : isMdScreen ? "60px" : "250px"
  const pagePadding = isSmScreen ? "5px" : isMdScreen ? "10px" : "15px"

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflow: "auto",
        boxSizing: "border-box",
        zIndex: 3,
        // position: "relative",
      }}
    >
      <Sidebar theme={theme} sidebarWidth={sidebarWidth} />
      <Box
        sx={{
          backgroundColor: "#FAFBFF",
          boxSizing: "border-box",
          height: "auto",
          minHeight: "100vh",
          width: isSmScreen ? "100%" : `calc(100% - ${sidebarWidth})`,
          padding: pagePadding,
          top: 0,
          marginLeft: isSmScreen ? 0 : sidebarWidth,
          position: "relative",
          // overflow: "auto",
        }}
      >
        <Outlet context={pagePadding} />
      </Box>
    </Box>
  )
}
