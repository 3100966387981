import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getTemplatesRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "customers/template/", {
    headers: headers,
    method: "GET",
  }).catch((e) => showSnackbar("Не удалось получить шаблоны"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить шаблоны")
  return false
}

const saveTemplateRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(
    process.env.REACT_APP_API_URL + `customers/template/${data.id}/update/`,
    {
      headers: headers,
      method: "PATCH",
      body: JSON.stringify(data),
    }
  ).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    showSnackbar("Шаблон сохранен")
    return resData
  }
  showSnackbar("Не удалось сохранить шаблон")
  return false
}

const createTemplateRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(
    process.env.REACT_APP_API_URL + `customers/template/create/`,
    {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }
  ).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    showSnackbar("Шаблон создан")
    return resData
  }
  showSnackbar("Не удалось создать шаблон")
  return false
}


export default function useTemplates() {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [templates, setTemplates] = useState(false)

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const templates = await getTemplatesRequest(headers, showSnackbar)
      setTemplates(templates)
    }
    load()
  }, [])

  const saveTemplate = (data) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      saveTemplateRequest(data, h, showSnackbar).then((d) => {
        if (d) {
          setTemplates(templates.map(t => t.id === d.id ? d : t))
        }
      })
    })
  }

  const createTemplate = (data) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      createTemplateRequest(data, h, showSnackbar).then((d) => {
        if (d) {
          setTemplates(templates.map(t => t.id === d.id ? d : t))
        }
      })
    })
  }


  return { templates, saveTemplate, createTemplate }
}
