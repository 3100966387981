import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"

const createTaskRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "tasks/", {
    headers: headers,
    method: "POST",
    body: JSON.stringify(data),
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    console.log(resData)
    return resData
  }
  showSnackbar("Не удалось создать задачу")
  return false
}

const saveTaskRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(
    process.env.REACT_APP_API_URL + `tasks/${data.id}/update/`,
    {
      headers: headers,
      method: "PATCH",
      body: JSON.stringify(data),
    }
  ).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    showSnackbar("Задача сохранена")
    return resData
  }
  showSnackbar("Не удалось сохранить задачу")
  return false
}

const getTasksRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "tasks/", {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить задачи")
  return false
}

const getFiltersRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "tasks/fields/", {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    console.log(resData)
    return resData
  }
  showSnackbar("Не удалось получить задачи")
  return false
}

export default function useTasks() {
  const [nonFilteredTasks, setNonFilteredTasks] = useState()
  const [tasks, setTasks] = useState(false)
  const [fields, setFields] = useState(false)
  const [filters, setFilters] = useState(false)
  const { getAuthHeader, user } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const tasksValue = await getTasksRequest(headers, showSnackbar)
      setTasks(tasksValue)
      setNonFilteredTasks(tasksValue)
      setFields(await getFiltersRequest(headers, showSnackbar))
    }
    if (!filters) load()
  }, [])

  useEffect(() => {
    console.log(filters)
    if (filters && nonFilteredTasks.count > 1) {
      const newTasks = nonFilteredTasks.results.filter((t) => {
        if (filters?.status && filters.status !== -1) {
          if (t.status !== filters.status) return
        }
        if (filters?.deparment && filters.deparment !== -1) {
          if (t.deparment !== filters.deparment) return
        }
        return t
      })
      setTasks({ count: newTasks.length, results: newTasks })
    }
  }, [filters])

  const createTask = (data) => {
    // data.author = user.pk
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      createTaskRequest(data, h, showSnackbar).then((d) => {
        if (d) {
          setTasks({ ...tasks, results: [...tasks.results, d] })
          setNonFilteredTasks({
            ...nonFilteredTasks,
            results: [...nonFilteredTasks.results, d],
          })
        }
      })
    })
  }

  const saveTask = (data, onClose) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      saveTaskRequest(data, h, showSnackbar).then((d) => {
        if (d) {
          setTasks({ ...tasks, results: [...tasks.results, d] })
          setNonFilteredTasks({
            ...nonFilteredTasks,
            results: [...nonFilteredTasks.results, d],
          })
          onClose()
        }
      })
    })
  }

  return {
    tasks,
    setTasks,
    fields,
    setFilters,
    createTask,
    saveTask,
  }
}
