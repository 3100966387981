import React from "react"
import { Box, Typography, useTheme } from "@mui/material"
import { Cell, Legend, Pie, PieChart } from "recharts"

export default function MainAnalytics({ width, height, data }) {
  const theme = useTheme()
  if (Object.keys(data).length === 1) return <></>

  // const per = (partialValue, totalValue) =>
  //   ((100 * partialValue) / totalValue).toFixed(2)

  // const readyData = []
  let readyData = []
  for (const [key, value] of Object.entries(data)) {
    if (value.color) {
      if (value.percentage) {
        readyData.push({
          name: `${value.title}: ${value.count} (${Math.round(
            value.percentage
          )}%)`,
          value: value.count,
          fill: theme.palette[value.color.toLowerCase()].main,
        })
      }
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <PieChart width={width} height={height}>
        <Legend
          // style={{ display: "flex", gap: "10px" }}
          layout="vertical"
          verticalAlign={"middle"}
          align="right"
        />
        <Pie
          data={readyData}
          label={false}
          labelLine={false}
          // innerRadius={width * 0.2 - 50}
          outerRadius={height * 0.25}
          fill="#8884d8"
          // paddingAngle={5}
          dataKey="value"
          // radius={innerRadius + (outerRadius - innerRadius) * 0.5}
        ></Pie>
        {readyData.map((entry, index) => (
          <Cell key={`cell-${index}`} />
        ))}
      </PieChart>
    </Box>
  )
}
