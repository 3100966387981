import React from "react"
import styles from "./NotFoundPage.module.css"
import { Link } from "react-router-dom"

export default function NotFoundPage (){
  return (
    <div className={styles.page}>
      <h1 className={styles.error}>404</h1>
      <span className={styles.errorText}>Похоже, такой страницы не существует...</span>
      <Link to={'/'} class={styles.goHomeButton}>
        {/* <!-- Download SVG icon from http://tabler-icons.io/i/arrow-left --> */}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
            />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
        </svg>
        На главную
      </Link>
    </div>
  )
}