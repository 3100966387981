import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getDepartmentsRequest = async (headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "departments/", {
    headers: headers,
    method: "GET",
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить подразделения")
  return false
}

const createDepartmentRequest = async (data, headers, showSnackbar) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "departments/", {
    headers: headers,
    method: "POST",
    body: JSON.stringify(data)
  }).catch((e) => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось создать подразделение")
  return false
}

export default function useDepartments() {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [departments, setDepartments] = useState(false)

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const departments = await getDepartmentsRequest(headers, showSnackbar)
      setDepartments(departments)
    }
    load()
  }, [])

  const createDepartment = (data) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      createDepartmentRequest(data, h, showSnackbar).then((d) => {
        if (d)
          setDepartments({
            ...departments,
            results: [...departments.results, d],
          })
      })
    })
  }

  return { departments, createDepartment }
}
