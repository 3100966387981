import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)){
      if (value && value !== -1) params.append(key.toString(), value)
    }else{
      if (key !== "phone") {
        if (value){
          params.append('results[]', key)
        }
      }
    }
  }
  return params
}

const getOrdersRequest = async (startDate, endDate, filters, fields, headers, page, perPage, showSnackbar) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    page: page,
    page_size: perPage,
    phone: filters?.phone ? filters.phone : ""
  })
  if (filters && fields) params = filtersToParams(filters, params, fields)
  const res = await fetch(process.env.REACT_APP_API_URL + 'feedback/?' + params.toString(), {
    method: 'GET',
    headers: headers
  }).catch(e => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
  }
  if (!res?.ok) return false
  return await res.json()
}

export default function useOrders(page, perPage, startDate, endDate, filters, fields){
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const [orders, setOrders] = useState(false)
  
  useEffect(() => {
    setOrders(false)
    const getOrders = async () => {
      const headers = await getAuthHeader()
      setOrders(await getOrdersRequest(startDate, endDate, filters, fields, headers, page, perPage, showSnackbar))
    }
    getOrders()
  }, [filters, startDate, endDate, page, perPage])
  
  return { orders, setOrders }
}