import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import dayjs from "dayjs"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)){
      if (value && value !== -1) params.append(key.toString(), value)
    }else{
      if (key !== "phone"){
        if (key !== "from_new_customer"){
          if (value){
            params.append('results[]', key)
          }
        }
      } 
    }
  }
  return params
}

const getCallsRequest = async (startDate, endDate, filters, fields, headers, page, perPage, showSnackbar) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    page: page + 1,
    page_size: perPage,
    phone: filters?.phone ? filters.phone : "",
    keyword: filters?.keyword ? filters.keyword : "",
  })
  if (filters.from_new_customer){
    params.append("from_new_customer", 1)
  }
  if (filters && fields) params = filtersToParams(filters, params, fields)
  const res = await fetch(process.env.REACT_APP_API_URL + 'calls/?' + params.toString(), {
    method: 'GET',
    headers: headers
  }).catch(e => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
  }
  if (!res?.ok) return false
  return await res.json()
}

export default function useCalls(filters=false, fields, startDate=dayjs(new Date()), endDate=dayjs(new Date()), page=0, perPage=10){ 
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const [calls, setCalls] = useState(false)

  useEffect(() => {
    setCalls(false)
    const getCalls = async () => {
      const headers = await getAuthHeader()
      const callsData = await getCallsRequest(startDate, endDate, filters, fields, headers, page, perPage, showSnackbar)
      setCalls(callsData)
    }
    getCalls()
  }, [filters, startDate, endDate, page, perPage])

  return { calls, setCalls }
}