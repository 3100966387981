import React, { useState } from "react"
import { Grid } from "@mui/material"
import MailListFilter from "../MailListFilter"
import DataCustomers from "../DataCustomers"
import SendMailList from "../SendMailList"
import { useForm } from "react-hook-form"
import useFilters from "../../../hooks/useFilters"
import useCustomers from "../../../hooks/useCustomers"
import TemplateModal from "../TemplatesModal"

export default function Feedback({ startDate, endDate, setModal, size }) {
  const form = useForm({ mode: "onBlur" })
  const { fields, filters, setFilters } = useFilters("selects/feedback/")
  const [templateModal, setTemplateModal] = useState(false)
  const { customers } = useCustomers(
    startDate,
    endDate,
    fields,
    filters,
    "feedback/counter/"
  )

  return (
    <Grid
      container
      // spacing={2}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        boxSizing: "border-box",
      }}
    >
      {templateModal && (
        <TemplateModal
          onClose={() => setTemplateModal(false)}
          setText={(t) => form.setValue("text", t)}
        />
      )}
      <Grid item xs={12} sm={9} md={9} lg={9} p={2}>
        <MailListFilter
          fields={fields}
          onApplyFilter={(f) => setFilters(f)}
          onResetFilter={() => setFilters(false)}
          checkboxsStats={customers?.stats}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} p={2}>
        <DataCustomers
          customers={customers}
          startDate={startDate}
          endDate={endDate}
          fields={fields}
          filters={filters}
          url={"feedback/download_file/"}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
        <SendMailList
          url={"feedback/send/"}
          startDate={startDate}
          endDate={endDate}
          fields={fields}
          filters={filters}
          mailForm={form}
          size={size}
          setModal={setModal}
          setTemplateModal={setTemplateModal}
        />
      </Grid>
    </Grid>
  )
}
