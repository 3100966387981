import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { Navigate, useLocation } from "react-router-dom"
import { useAuthContext } from "../../../hocs/AuthProvider"

export default function Restore() {
  const [redirect, setRedirect] = useState(false)
  const { restoreSignIn } = useAuthContext()
  const location = useLocation()
  const targetPath = location.state?.pathname || "/u/"

  useEffect(() => {
    restoreSignIn()
      .then((restore) => {
        if (restore) setRedirect(targetPath)
        else setRedirect("/signin")
      })
      .catch((c) => setRedirect("/signin"))
  }, [])

  if (redirect) return <Navigate to={redirect} replace />

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>Пожалуйста, подождите...</Typography>
    </Box>
  )
}
