import React from "react"
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom"
import { AuthProvider } from "./hocs/AuthProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/ru"
import PrivatePage from "./hocs/PrivatePage"
import NotFoundPage from "./pages/NotFoundPage"
import SignUp from "./pages/auth/SignUp"
import SignIn from "./pages/auth/SignIn"
import Feedback from "./pages/Feedback"
import Analytics from "./pages/Analytics"
import Main from "./pages/Main"
import LkMain from "./pages/LkMain"
import Operator from "./pages/Operator"
import LkLayout from "./components/LkLayout"
import { Box, ThemeProvider, createTheme } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { SnackbarProvider } from "./hocs/SnackbarProvider"
import Tasks from "./pages/Tasks"
import { SidebarProvider } from "./hocs/SidebarProvider"
import Restore from "./pages/auth/Restore"
import Marketer from "./pages/Marketer"
import Stats from "./pages/Stats"
import Profile from "./pages/Profile"
import Company from "./pages/Company"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <section className="page">
        <Outlet />
      </section>
    ),
    children: [
      {
        index: true,
        path: "",
        element: <Main />,
      },
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "restore",
        element: <Restore />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/u/",
    element: <LkLayout />,
    children: [
      {
        index: true,
        path: "",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "MANAGER", "OPERATOR", "SPECIAL_GUEST"]}
          >
            <LkMain />
          </PrivatePage>
        ),
      },
      {
        path: "company",
        element: (
          <PrivatePage allowedPageTypes={["OWNER"]}>
            <Company />
          </PrivatePage>
        ),
      },
      {
        path: "operator",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "OPERATOR", "MANAGER", "EMPLOYEE"]}
          >
            <Operator />
          </PrivatePage>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "OPERATOR", "MANAGER", "EMPLOYEE"]}
          >
            <Profile />
          </PrivatePage>
        ),
      },
      {
        path: "stats",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER"]}>
            <Stats />
          </PrivatePage>
        ),
      },
      {
        path: "analytics",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "SPECIAL_GUEST"]}>
            <Analytics />
          </PrivatePage>
        ),
      },
      {
        path: "feedback",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "EMPLOYEE"]}>
            <Feedback />
          </PrivatePage>
        ),
      },
      {
        path: "tasks",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "EMPLOYEE"]}>
            <Tasks />
          </PrivatePage>
        ),
      },
      {
        path: "marketer",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER"]}>
            <Marketer />
          </PrivatePage>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
])

const theme = createTheme({
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
  },
  palette: {
    // mode: "dark",
    primary: {
      light: "#4BF5CD",
      main: "#16C098",
      dark: "#009B73",
      contrastText: "#fff",
    },
    secondary: {
      light: "#d86070",
      main: "#A72335",
      dark: "#811c2c",
      contrastText: "#fff",
    },
    other: {
      light: "#FFD946",
      main: "#ffbb28",
      dark: "#E19D0A",
      contrastText: "#fff",
    },
    green: {
      main: "#16C099",
      contrastText: "#fff",
    },
    red: {
      main: "#A82336",
      dark: "#791e2b",
      contrastText: "#fff",
    },
    yellow: {
      main: "#E0D100",
      contrastText: "#fff",
    },
    purple: {
      main: "#7051E3",
      contrastText: "#fff",
    },
    orange: {
      main: "#D88359",
      contrastText: "#fff",
    },
    gray: {
      main: "#7390B8",
      contrastText: "#fff",
    },
    blue: {
      main: "#4174D9",
      dark: "#2356BB",
      contrastText: "#fff",
    },
    brown: {
      main: "#6A4A3A",
      dark: "#4C3228",
      contrastText: "#fff",
    },
    pink: {
      main: "#E44B8D",
      dark: "#A53E76",
      contrastText: "#fff",
    },
    turquoise: {
      main: "#40E0D0",
      dark: "#008080",
    },
  },
})

export default function App() {
  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <SidebarProvider>
              <Box sx={{ width: "100%", height: "100%" }}>
                <RouterProvider router={router} />
              </Box>
            </SidebarProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </AuthProvider>
  )
}
