import React from "react"
import { Box, Grid, Skeleton, Typography } from "@mui/material"
import { DeleteOutline, DoDisturb, Done } from "@mui/icons-material"

const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100px",
        overflow: "hidden",
        borderRadius: "15px",
        margin: 0,
        boxSizing: "border-box",
      }}
    >
      <Skeleton
        sx={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  )
}

const CustomCard = ({ data, icon, color, header }) => {
  return (
    <Grid item lg={4} md={4} sm={6} xs={12}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "15px",
        }}
        p={1}
      >
        <Box
          sx={{
            background: color,
            borderRadius: "100%",
            width: "70px",
            height: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src={icon}/> */}
          {icon}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="span" sx={{ fontWeight: "600" }}>
            {header}
          </Typography>
          <Typography variant="h5">{data}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default function ShortStats({ data = false }) {
  if (!data) return <Loading />

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "auto",
        borderRadius: "15px",
        background: "#fff",
        boxSizing: "border-box",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, .5)",
      }}
    >
      {/* {data.map((d) => (
        <CustomCard />
      ))} */}
      <CustomCard
        header={"Успешных звонков"}
        icon={<Done sx={{ width: "50%", height: "50%", color: "#32AA7A" }} />}
        data={data?.success && data.success.count ? data.success.count : "-"}
        color={"#E3FFF1"}
      />
      <CustomCard
        header={"Слитых звонков"}
        icon={
          <DoDisturb sx={{ width: "50%", height: "50%", color: "#A35666" }} />
        }
        data={data?.fail && data.fail.count ? data.fail.count : "-"}
        color={"#FBDFE2"}
      />
      <CustomCard
        header={"Мусор"}
        icon={
          <DeleteOutline sx={{ width: "50%", height: "50%", color: "#000" }} />
        }
        data={data?.waste && data.waste.count ? data.waste.count : "-"}
        color={"#F2F2F2"}
      />
    </Grid>
  )
}
