import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Grid,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  IconButton,
  useTheme,
  Input,
  Tab,
} from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useOutletContext } from "react-router-dom"
import dayjs from "dayjs"
import Header from "../../components/Header"
import ConfirmModal from "./ConfirmModal"
import Feedback from "./Feedback"
import Calls from "./Calls"
import File from "./File"

const CustomTabPanel = ({ children, value }) => {
  return (
    <TabPanel
      value={value}
      sx={{
        padding: 0,
        paddingTop: 2,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </TabPanel>
  )
}

export default function Marketer() {
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [tab, setTab] = useState("0")
  const handleChange = (e, v) => setTab(v)
  const [modal, setModal] = useState()
  const pagePadding = useOutletContext()
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))
  const size = isSmScreen ? "small" : "medium"

  return (
    <TabContext value={tab}>
      {modal && (
        <ConfirmModal
          modal={modal}
          size={size}
          onClose={() => setModal(false)}
        />
      )}
      <Box
        flexDirection={"column"}
        display={"flex"}
        sx={{
          // gap: pagePadding,
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Header
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <TabList
          sx={{ width: "100%", height: "50px" }}
          onChange={handleChange}
          allowScrollButtonsMobile
          aria-label="lab API tabs example"
        >
          <Tab label="Звонки" value="0" />
          <Tab label="Обратная связь" value="1" />
          <Tab label="Файл" value="2" />
        </TabList>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            overflow: "hidden",
            // background: "red",
          }}
        >
          <CustomTabPanel value={"0"}>
            <Calls
              setModal={setModal}
              size={size}
              startDate={startDate}
              endDate={endDate}
            />
          </CustomTabPanel>
          <CustomTabPanel value={"1"}>
            <Feedback
              setModal={setModal}
              size={size}
              startDate={startDate}
              endDate={endDate}
            />
          </CustomTabPanel>
          <CustomTabPanel value={"2"}>
            <File
              setModal={setModal}
              size={size}
              startDate={startDate}
              endDate={endDate}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </TabContext>
  )
}
