import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"

const getFiltersRequest = async (headers, showSnackbar, url) => {
  const res = await fetch(process.env.REACT_APP_API_URL + url, {
    headers: headers,
    method: 'GET',
  }).catch(e => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
  }
  if (!res?.ok) return false
  const data = await res.json()
  let selects = []
  for (const [key, value] of Object.entries(data.selects)) {
    const s = {...value, options: [...value.options, {id: -1, title: 'Не выбрано'}]}
    selects.push(s)
  }
  const f = {
    checkboxs: data.results,
    selects: selects
  }
  return f
}

export default function useFilters (url=''){
  const [filters, setFilters] = useState(false)
  const [fields, setFields] = useState(false)
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      setFields(await getFiltersRequest(headers, showSnackbar, url))
    }
    load()
  }, [])

  return { fields, filters, setFilters }
}