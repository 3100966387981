import React, { createContext, useContext } from "react"
import { useSidebar } from "../hooks/useSidebar"

const SidebarContext = createContext()
const useSidebarContext = () => useContext(SidebarContext)

const SidebarProvider = ({ children }) => {
  const sidebar = useSidebar()

  return (
    <SidebarContext.Provider value={sidebar}>
      {children}
    </SidebarContext.Provider>
  )
}

export { useSidebarContext, SidebarProvider }
