import React, { useState } from "react"
import {
  Box,
  List,
  ListItem,
  TextField,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
} from "@mui/material"

import useDepartments from "../../../hooks/useDepartments"
import { useForm } from "react-hook-form"

function AddDepartment({ create }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" })
  const onCreate = (d) => {
    if (d) create(d)
  }
  return (
    <TableRow>
      <TableCell sx={{ borderBottom: "none" }}>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form onSubmit={handleSubmit(onCreate)}>
            <TextField
              {...register("title", {
                maxLength: {
                  value: 150,
                  message: "Максимум 150 символов",
                },
              })}
              size="small"
              label="Название"
            />
            <Button type="submit" variant="contained" size="small">
              Добавить
            </Button>
          </form>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default function DepartmentList() {
  const { departments, createDepartment } = useDepartments()

  return (
    <Box sx={{ width: "100%" }}>
      <Table sx={{ borderRadius: "15px", width: "100%", background: "#ccc" }}>
        {departments ? (
          departments.results.map((d) => (
            <TableRow>
              <TableCell>
                <Typography>{d.title}</Typography>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Typography>Загрузка</Typography>
        )}
        <AddDepartment create={createDepartment} />
      </Table>
    </Box>
  )
}
