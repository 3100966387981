import React, { useState } from "react"
import useStats from "../../hooks/useStats"
import { Box, Grid, Typography, Tab } from "@mui/material"
import { useOutletContext } from "react-router-dom"
import ShortStats from "./ShortStats"
import CustomCard from "./CustomCard"
import Header from "../../components/Header"
import dayjs from "dayjs"
import { Cell, Pie, PieChart } from "recharts"
import RepeatCalls from "./RepeatCalls"
import MainAnalytics from "./MainAnalytics"
import Feedback from "./Feedback"
import Masters from "./Masters"
import Operator from "./Operator"
import { TabContext, TabList, TabPanel } from "@mui/lab"

const CustomTabPanel = ({ children, value }) => {
  return (
    <TabPanel
      value={value}
      sx={{
        padding: 0,
        paddingTop: 2,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </TabPanel>
  )
}

export default function Stats() {
  const pagePadding = useOutletContext()
  const [startDate, setStartDate] = useState(
    dayjs(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  )
  const [tab, setTab] = useState("0")
  const handleChange = (e, v) => setTab(v)
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const { analytics } = useStats(startDate, endDate)

  return (
    <TabContext value={tab}>
      <Box
        flexDirection={"column"}
        sx={{
          display: "flex",
          gap: pagePadding,
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Header
          header="Статистика"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <TabList
          sx={{ width: "100%", height: "50px" }}
          onChange={handleChange}
          allowScrollButtonsMobile
          aria-label="lab API tabs example"
        >
          <Tab label="Общая" value="0" />
          <Tab label="Мастера" value="1" />
        </TabList>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            // padding: "15px",
            overflow: "auto",
          }}
        >
          <CustomTabPanel value={"0"}>
            <Grid container spacing={2} sx={{ boxSizing: "border-box" }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ShortStats data={analytics?.calls ? analytics.calls : false} />
              </Grid>
              <CustomCard
                isDataLoading={analytics}
                header={`Общая аналитика: ${
                  analytics?.calls && analytics.calls.total_good
                    ? analytics.calls.total_good.count
                    : ""
                }`}
              >
                <MainAnalytics data={analytics.calls} />
              </CustomCard>
              <CustomCard isDataLoading={analytics} header="Повторные звонки">
                <RepeatCalls data={analytics} />
              </CustomCard>

              <CustomCard isDataLoading={analytics} header="Обратная связь">
                <Feedback data={analytics.feedback} />
              </CustomCard>

              <CustomCard isDataLoading={analytics} header="Операторы">
                <Operator data={analytics.operator} />
              </CustomCard>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={"1"}>
            <Masters data={analytics.masters} />
          </CustomTabPanel>
        </Box>
      </Box>
    </TabContext>
  )
}
