import React from "react"
import Filter from "../../../components/Filter"
import { Box } from "@mui/material"

export default function MailListFilter({
  fields,
  onApplyFilter,
  onResetFilter,
  checkboxsStats,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
      }}
    >
      <Filter
        fields={fields}
        onApplyFilter={onApplyFilter}
        onResetFilter={onResetFilter}
        checkboxsStats={checkboxsStats}
      />
    </Box>
  )
}
