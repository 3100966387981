import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import Filter from "../../components/Filter"
import useFilters from "../../hooks/useFilters"
import Orders from "./Orders"
import useOrders from "../../hooks/useOrders"
import { useOutletContext } from "react-router-dom"

export default function Feedback() {
  const { fields, filters, setFilters } = useFilters("selects/feedback/")
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [fullTable, setFullTable] = useState(false)
  const { orders, setOrders } = useOrders(
    page,
    perPage,
    startDate,
    endDate,
    filters,
    fields
  )
  const pagePadding = useOutletContext()

  useEffect(() => setPage(1), [filters, startDate, endDate])

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        height: "100%",
        width: "100%",
        gap: pagePadding,
        boxSizing: "border-box",
      }}
    >
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: fullTable ? "none" : "flex",
          flexFlow: "column",
          gap: pagePadding,
          boxSizing: "border-box",
        }}
      >
        <Filter
          fields={fields}
          onApplyFilter={(d) => setFilters(d)}
          onResetFilter={() => setFilters(false)}
          count={orders?.count ? orders.count : 0}
          checkboxsStats={orders.stats}
        />
      </Box>
      <Orders
        orders={orders}
        setOrders={setOrders}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        fields={fields}
        filters={filters}
        setFilters={setFilters}
        fullTable={fullTable}
        setFullTable={setFullTable}
      />
    </Box>
  )
}
