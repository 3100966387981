import React from "react"
import {
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
} from "@mui/material"
import closeIcon from "../../assets/images/close.png"
import { Controller, useForm } from "react-hook-form"
import { useSnackbarContext } from "../../hocs/SnackbarProvider"
import { useAuthContext } from "../../hocs/AuthProvider"

// const saveTaskRequest = async (headers, showSnackbar) => {}

const TaskForm = ({
  task,
  onClose,
  fields,
  register,
  handleSubmit,
  errors,
  control,
  size,
}) => {
  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexFlow: "column",
        gap: "15px",
        boxSizing: "border-box",
      }}
    >
      <FormControl sx={{ width: "100%", boxSizing: "border-box" }} size={size}>
        <InputLabel variant={"outlined"} id={`taks-modal-select-label-id`}>
          {"Статус"}
        </InputLabel>
        <Controller
          defaultValue={task.status || "open"}
          render={({ field }) => (
            <Select
              label={"Статус"}
              sx={{ width: "100%", boxSizing: "border-box" }}
              variant="outlined"
              size={size}
              labelId={`task-modal-select-label-id`}
              {...field}
            >
              {fields.results.map((op) => (
                <MenuItem key={op.pk} value={op.pk}>
                  {op.title}
                </MenuItem>
              ))}
            </Select>
          )}
          name={"status"}
          control={control}
        />
      </FormControl>
      <TextField
        size={size}
        multiline
        {...register("comment", {
          maxLength: {
            value: 300,
            message: "Максимум 300 символов",
          },
          value: task.comment,
        })}
        label={"Комментарий"}
        error={errors?.comment}
        helperText={errors?.comment?.message}
      ></TextField>
      <TextField
        size={size}
        multiline
        {...register("title", {
          maxLength: {
            value: 500,
            message: "Максимум 500 символов",
          },
          required: "Обязательное поле",
          value: task.title,
        })}
        label={"Заголовок *"}
        error={errors?.title}
        helperText={errors?.title?.message}
      ></TextField>
    </Box>
  )
}

const ModalHeader = ({ onClose, task }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{`Задача: ${task.id || "-"}`}</Typography>
      <IconButton
        sx={{
          width: "40px",
          padding: "7px",
          marginRight: "-7px",
          height: "40px",
        }}
        onClick={onClose}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={closeIcon}
          alt="close"
        />
      </IconButton>
    </Box>
  )
}

export default function TaskModal({
  onClose,
  task = false,
  saveTask,
  createTask,
  setTasks,
  fields,
}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" })
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))
  const size = isSmScreen ? "small" : "medium"

  const onSubmit = (data) => {
    if (task === "create") {
      data.executor_id = 1
      onClose()
      return createTask(data)
    }

    data.id = task.id
    saveTask(data, onClose)
  }

  return (
    <Box
      onClick={onClose}
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.4)",
        zIndex: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <form
        style={{ width: isSmScreen ? "95%" : "500px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          p={2}
          sx={{
            background: "#fff",
            borderRadius: "15px",
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          <ModalHeader task={task} onClose={onClose} />
          <TaskForm
            register={register}
            size={size}
            fields={fields}
            task={task}
            errors={errors}
            control={control}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color={"secondary"}
              onClick={onClose}
              size={size}
            >
              Отмена
            </Button>
            <Button
              color={"primary"}
              type="submit"
              variant="contained"
              onClick={handleSubmit((d) => onSubmit(d, false))}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}
