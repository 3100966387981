import React from "react"
import { useLocation, Navigate } from "react-router-dom"
import { useAuthContext } from "../AuthProvider"

export default function PrivatePage({
  children,
  allowedPageTypes = ["OWNER"],
}) {
  const { user } = useAuthContext()
  const location = useLocation()

  if (user == "logout") return <Navigate to={"/signin"} replace />

  if (user && user?.type && allowedPageTypes.includes(user.type))
    return children

  // Restore redirect
  return <Navigate to={"/restore"} replace state={location} />
}
