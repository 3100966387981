import { useState } from "react"

export const useSnackBarCustom = (isDefaultOpen=false, messageText='') => {
  const [open, setOpen] = useState(isDefaultOpen)
  const [message, setMessage] = useState(messageText)
  
  const onClose = () => setOpen(false)
  const showSnackbar = (m='') => {
    setOpen(true)
    if (m) setMessage(m)
  }

  const props = { open: open, onClose: onClose, autoHideDuration: 4000 }

  return { props, open, setOpen, onClose, message, showSnackbar }
}