import React, { cloneElement, useEffect, useRef, useState } from "react"
import { Box, Grid, Skeleton, Typography } from "@mui/material"

const Loading = () => {
  return (
    <Skeleton
      sx={{
        margin: 0,
        width: "100%",
        height: "200px",
        borderRadius: "15px",
        boxSizing: "border-box",
      }}
    />
  )
}

export default function CustomCard({
  isDataLoading = false,
  children,
  header = "",
}) {
  const [width, setWidth] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth)
    }
  }, [isDataLoading])

  return (
    <Grid
      item
      lg={6}
      md={12}
      sm={12}
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        boxSizing: "border-box",
        maxHeight: "500px",
      }}
    >
      {!isDataLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            padding: "15px",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            background: "#fff",
            borderRadius: "15px",
            boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h6">{header}</Typography>
          <Box
            ref={ref}
            sx={{
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cloneElement(children, { width: width, height: 400 })}
          </Box>
        </Box>
      )}
    </Grid>
  )
}
